// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { h1Style, introStyle } from '../styles/global';
import HeroOffset from '../components/HeroOffset';

import Stamp20Ans from '../images/Stamp20Ans';
import colors from '../styles/colors';
import responsiveStyle from '../utils/responsiveStyle';
import breakpoints from '../styles/breakpoints';
import LinkCardList from '../components/LinkCardList';
import SEO from '../components/SEO';

const StampContent = (
  <div style={{ color: colors.black }}>
    <Stamp20Ans />
  </div>
);

const ServiceSection = styled.section`
  padding: 0;

  ${responsiveStyle('marginTop', [64, 168], breakpoints.spacings)};
  ${responsiveStyle('paddingTop', [64, 168], breakpoints.spacings)};
  ${responsiveStyle('paddingBottom', [64, 168], breakpoints.spacings)};

  background-color: ${colors.veryLightPink};
`;

const ServicesPage = ({ data }) => {
  const {
    headerImageDesktop: {
      childImageSharp: { fluid: headerImageDesktop },
    },
    services: { edges: services = [] },
  } = data;

  const linkNodes = services.map(({ node }) => ({
    abbreviation: node.abbreviation,
    name: node.name,
    to: `/services/${node.slug.current}`,
  }));

  return (
    <Layout hasNoHeaderSpacer>
      <SEO
        title='Services'
        description='Nos cliniques Maxillo Québec Lebourgneuf et Lévis offrent des services chirurgicaux dentaires telles les dents de sagesse et les implants dentaires.'
      />
      <article>
        <HeroOffset img={headerImageDesktop} stamp={StampContent}>
          <h1 css={h1Style}>Les services offerts à&nbsp;la&nbsp;clinique</h1>

          <p css={introStyle}>
            Chez Maxillo Québec, nos spécialistes sont des professionnels
            chevronnés dotés d’une expérience des plus précises. Chacun de nos
            cabinets offre une vaste gamme de services.
          </p>
        </HeroOffset>

        <ServiceSection>
          <LinkCardList nodes={linkNodes} />
        </ServiceSection>
      </article>
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    headerImageDesktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    services: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            name: PropTypes.string.isRequired,
            abbreviation: PropTypes.string.isRequired,
            slug: PropTypes.shape({
              current: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        })
      ),
    }),
  }).isRequired,
};

export default ServicesPage;

export const query = graphql`
  query {
    headerImageDesktop: file(name: { eq: "img-header-desktop-services" }) {
      ...HeroOffsetPictureData
    }
    services: allSanityService(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
          abbreviation
        }
      }
    }
  }
`;
