// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const Stamp20Ans = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M4.4 68.5l9.6 3.2a5.2 5.2 0 003.3.3 3.2 3.2 0 001.9-2.3 3.2 3.2 0 00-.1-2.9 5.2 5.2 0 00-2.8-1.8l-9.6-3.2.8-2.5 9.2 3.1a13.3 13.3 0 012.9 1.3 5.3 5.3 0 011.7 1.7 4.4 4.4 0 01.6 2.2 8.6 8.6 0 01-.5 2.9 8.9 8.9 0 01-1.3 2.6 4.4 4.4 0 01-1.8 1.4 5.2 5.2 0 01-2.4.3 12.7 12.7 0 01-3.1-.7L3.5 71z' />
      <path d='M25.3 60L15 53.4l1.4-2.1 1.7 1a4 4 0 01-.7-2 3.4 3.4 0 01.7-2.2 3.7 3.7 0 012.6-1.8 5.4 5.4 0 013.5 1l6.6 4.2-1.4 2.2-6.3-4c-1.6-1-2.8-1-3.7.4a3.7 3.7 0 00-.4.9 3.1 3.1 0 00-.1 1 2 2 0 00.2.8 2 2 0 00.7.7l6.9 4.4z' />
      <path d='M37 44.2a5.8 5.8 0 01-1.9 1.5 4.9 4.9 0 01-2.1.5 5.9 5.9 0 01-2.3-.5 9.4 9.4 0 01-4-3.5 5.9 5.9 0 01-.8-2.1 4.8 4.8 0 01.1-2.2 6 6 0 011.2-2.1 5.8 5.8 0 012-1.5 4.7 4.7 0 012-.4 5.6 5.6 0 012.2.5 8 8 0 012 1.3l.7.6-5.5 6.4.4.3a3.2 3.2 0 002.3.9 3.1 3.1 0 002.3-1.2 3.5 3.5 0 00.8-1.8 4.3 4.3 0 00-.2-1.9l2.1-.2a4.8 4.8 0 01.1 2.7 6.5 6.5 0 01-1.4 2.7zm-8.2-7a3 3 0 00-.6 1 2.6 2.6 0 00-.2 1.1 3 3 0 00.4 1.2 4 4 0 00.8 1h.1l3.8-4.3-.2-.1a3.4 3.4 0 00-2.2-1 2.5 2.5 0 00-2 1z' />
      <path d='M56.1 28a2 2 0 01-1.6.4 2.6 2.6 0 01-1.4-1v.1a3 3 0 01-.2 2.2 4.1 4.1 0 01-1.6 1.7 4 4 0 01-3 .8 3.5 3.5 0 01-2.3-1.7 3.2 3.2 0 01-.4-3 7 7 0 012.8-3l1.9-1.1-.6-1a2.4 2.4 0 00-1.4-1 2.6 2.6 0 00-2 .5 3 3 0 00-1.2 1.3 4.3 4.3 0 00-.3 1.6l-2-.3a5 5 0 01.5-2.4 5.6 5.6 0 012-2.1 5.4 5.4 0 013.7-1 4 4 0 012.8 2l3.3 5.2 1.1-.7 1.1 1.8zm-5.3 1.4a3.5 3.5 0 001.4-1.5 1.7 1.7 0 00-.1-1.6l-1-1.5-1.8 1.1c-1.5 1-2 2-1.4 2.9l.3.3a1.4 1.4 0 001 .8 2.5 2.5 0 001.6-.5z' />
      <path d='M56 13.8l2.4-1 .7 1.9h.1a3.3 3.3 0 01.6-2.1 3.8 3.8 0 011.8-1.4 4.3 4.3 0 014 .3 7.7 7.7 0 013 4 7.7 7.7 0 01.5 4.8 4.2 4.2 0 01-2.8 2.9 3.7 3.7 0 01-2.2.2 3.4 3.4 0 01-1.8-1.1h-.1l2.5 6.3-2.4 1zm8.8 7.7a2.8 2.8 0 001.7-1.6 3.2 3.2 0 00-.1-2.4l-.8-2a3.2 3.2 0 00-1.6-1.8 2.8 2.8 0 00-2.3 0 3.4 3.4 0 00-1.7 1.2 1.8 1.8 0 00-.1 1.7l1.5 4a1.8 1.8 0 001.3 1 3.3 3.3 0 002-.1z' />
      <path d='M72 8l2.5-.7.5 2a3.3 3.3 0 011-2 3.8 3.8 0 012-1 4.3 4.3 0 013.8.8 7.8 7.8 0 012.3 4.3 7.8 7.8 0 01-.1 5 4.3 4.3 0 01-3.2 2.4 3.8 3.8 0 01-2.2-.1 3.5 3.5 0 01-1.7-1.4l1.4 6.6-2.5.6zm7.5 8.8a2.8 2.8 0 002-1.3 3.2 3.2 0 00.2-2.4l-.5-2.1a3.3 3.3 0 00-1.3-2 2.8 2.8 0 00-2.3-.4 3.4 3.4 0 00-1.8 1 1.8 1.8 0 00-.4 1.7l1 4.1a1.8 1.8 0 001 1.3 3.3 3.3 0 002.1.1z' />
      <path d='M89.6 17.2L88.6 5l2.7-.2.1 2.4h.2a3.2 3.2 0 011-1.8 3.3 3.3 0 012.2-.9h.7l.2 2.5h-1a4.6 4.6 0 00-2.3.7 1.6 1.6 0 00-.7 1.5l.5 7.8z' />
      <path d='M104.5 17.5a6 6 0 01-2.3-.6 4.7 4.7 0 01-1.6-1.4 5.9 5.9 0 01-1-2.1 8.3 8.3 0 01-.2-2.7 8 8 0 01.5-2.6 6 6 0 011.3-2 5 5 0 012-1.1 6 6 0 012.3-.3 5.8 5.8 0 012.3.7 4.9 4.9 0 011.6 1.4 5.6 5.6 0 011 2 8 8 0 01.2 2.8 8.2 8.2 0 01-.5 2.6 6 6 0 01-1.3 2 4.8 4.8 0 01-2 1 5.9 5.9 0 01-2.3.3zm.2-2.1a2.9 2.9 0 002.2-.7 3.4 3.4 0 001-2.3l.1-2.1a3.3 3.3 0 00-.6-2.5 2.8 2.8 0 00-2-1 2.9 2.9 0 00-2.2.7 3.3 3.3 0 00-1 2.3L102 12a3.3 3.3 0 00.6 2.5 2.9 2.9 0 002 1z' />
      <path d='M118.6 20.1a6 6 0 01-2.1-1.1 4.7 4.7 0 01-1.3-1.7 5.8 5.8 0 01-.5-2.3 8.5 8.5 0 01.3-2.6 8.8 8.8 0 011.1-2.4 5.8 5.8 0 011.6-1.7 4.7 4.7 0 012-.7 6 6 0 012.4.2 5.2 5.2 0 012.7 1.6 4.8 4.8 0 011 2.6l-2.2.4a2.9 2.9 0 00-4.3-2.3 3.5 3.5 0 00-1.4 2l-.6 2.1a3.5 3.5 0 00.1 2.4 2.6 2.6 0 001.8 1.5 2.8 2.8 0 002 0 3.9 3.9 0 001.3-1.2l1.7 1.6a4.9 4.9 0 01-2.5 1.7 5.3 5.3 0 01-3 0z' />
      <path d='M134 6.5l2.4 1.1-3 6.6a4.1 4.1 0 011.9-1 3.4 3.4 0 012.3.4 3.6 3.6 0 012.1 2.3 5.3 5.3 0 01-.5 3.6l-3.2 7.1-2.4-1 3.1-6.8q1.2-2.6-.9-3.6a4 4 0 00-1-.3 3 3 0 00-1 0 2 2 0 00-.8.4 1.8 1.8 0 00-.5.7l-3.4 7.5-2.4-1z' />
      <path d='M143.8 32.2a5.9 5.9 0 01-1.7-1.7 4.8 4.8 0 01-.6-2 5.7 5.7 0 01.2-2.4 8.9 8.9 0 011.2-2.4 8.6 8.6 0 011.9-2 5.8 5.8 0 012-1 5 5 0 012.3 0 6 6 0 012.2 1 5.5 5.5 0 011.6 1.8 4.7 4.7 0 01.7 2 5.7 5.7 0 01-.3 2.3 8 8 0 01-1.1 2.1l-.6.8-6.9-4.9-.2.3a3.2 3.2 0 00-.7 2.4 3 3 0 001.5 2.1 3.4 3.4 0 001.8.7 4.4 4.4 0 001.9-.3l.4 2a4.9 4.9 0 01-2.7.4 6.4 6.4 0 01-2.9-1.2zm6.3-8.8a3.3 3.3 0 00-1.1-.5 2.8 2.8 0 00-1.2 0 3 3 0 00-1 .4 3.8 3.8 0 00-1 1v.1l4.6 3.3.1-.2a3.4 3.4 0 00.7-2.3 2.5 2.5 0 00-1.1-1.8z' />
      <path d='M162.5 47.2V47a4.2 4.2 0 01-1 .2 3.1 3.1 0 01-1 0 3 3 0 01-1-.6 4.2 4.2 0 01-1-.9 3.7 3.7 0 01-.9-3 5.2 5.2 0 012.1-3l6.2-5 1.6 2-6 4.7c-1.4 1.2-1.7 2.4-.7 3.6a3.8 3.8 0 00.7.7 2.5 2.5 0 00.8.4 1.9 1.9 0 00.9.1 1.9 1.9 0 00.9-.4l6.4-5 1.6 2-9.6 7.5-1.6-2z' />
      <path d='M165.4 54.4l11-5.6 1.1 2.3-1.8.9.1.1a4.1 4.1 0 012 .3 3.4 3.4 0 011.6 1.7 3.6 3.6 0 01.2 3.2 5.3 5.3 0 01-2.6 2.5l-7 3.6-1.1-2.3 6.6-3.4c1.7-.9 2.2-2 1.5-3.4a4 4 0 00-.5-.8 3.4 3.4 0 00-.8-.6 2 2 0 00-.8-.2 2 2 0 00-1 .2l-7.3 3.8z' />
      <path d='M183.3 63.3l1 2.4-11.4 4.5-1-2.4zm2.5.4a1.6 1.6 0 010-1.3 1.3 1.3 0 01.7-.6l.4-.2a1.3 1.3 0 011 0 1.5 1.5 0 01.8 1 1.6 1.6 0 010 1.2 1.4 1.4 0 01-.7.7l-.4.1a1.4 1.4 0 01-1 0 1.6 1.6 0 01-.8-1z' />
      <path d='M178.2 81.8a3.4 3.4 0 01-2-1A3.6 3.6 0 01175 79a4.3 4.3 0 01.8-3.9 7.7 7.7 0 014.3-2.3 7.7 7.7 0 015 .2 4.3 4.3 0 012.4 3.1 3.8 3.8 0 010 2.2A3.4 3.4 0 01186 80l2-.4.6 2.5-16.6 3.9-.5-2.5zm-.8-2.6a3.3 3.3 0 001 1.8 1.8 1.8 0 001.7.4l4-1a1.8 1.8 0 001.4-1 3.3 3.3 0 00.1-2.1 2.8 2.8 0 00-1.3-2 3.2 3.2 0 00-2.4-.2l-2 .5a3.2 3.2 0 00-2 1.3 2.8 2.8 0 00-.5 2.3z' />
      <path d='M179.6 97a4.7 4.7 0 01-1-.4 3 3 0 01-.7-.7 3.2 3.2 0 01-.5-1 4.1 4.1 0 01-.2-1.3 3.7 3.7 0 011.1-3 5.2 5.2 0 013.5-1.1l7.8-.2v2.5l-7.4.2c-2 0-2.8.9-2.8 2.4a3.9 3.9 0 00.1 1 2.8 2.8 0 00.5.9 2 2 0 00.6.5 1.8 1.8 0 001 .2l8.3-.2v2.6l-12.3.3v-2.6z' />
      <path d='M176.4 109.3a5.8 5.8 0 01.8-2.3 4.8 4.8 0 011.6-1.5 5.8 5.8 0 012.2-.7 8.5 8.5 0 012.7 0 8.3 8.3 0 012.5 1 5.7 5.7 0 011.9 1.4 5.1 5.1 0 011 2 5.9 5.9 0 010 2.4 5.7 5.7 0 01-1 2.2 4.8 4.8 0 01-1.5 1.5 5.7 5.7 0 01-2.1.6 7.8 7.8 0 01-2.4 0l-1-.2 1.4-8.3h-.4a3.2 3.2 0 00-2.4.3 3.1 3.1 0 00-1.3 2.2 3.5 3.5 0 00.2 2 4.3 4.3 0 001 1.6l-1.6 1.2a4.8 4.8 0 01-1.4-2.3 6.5 6.5 0 01-.2-3.1zm10.6 1.9a3.2 3.2 0 000-1.2 2.8 2.8 0 00-1.3-1.9 3.6 3.6 0 00-1.3-.4h-.1l-1 5.6h.2a3.4 3.4 0 002.4-.3 2.5 2.5 0 001.2-1.8z' />
      <path d='M171.5 133v-.1a3.4 3.4 0 01-1-1.9 3.8 3.8 0 01.3-2.2 4.3 4.3 0 013-2.6 7.7 7.7 0 014.8.8 7.8 7.8 0 013.9 3.1 4.3 4.3 0 010 4 3.8 3.8 0 01-1.5 1.7 3.3 3.3 0 01-2 .5v.1l6.5 3-1 2.3-16-7.2 1-2.3zm1-2.6a3.2 3.2 0 00-.2 2 1.8 1.8 0 001 1.4l3.8 1.7a1.8 1.8 0 001.7 0 3.2 3.2 0 001.4-1.7 2.8 2.8 0 000-2.3 3.2 3.2 0 00-1.7-1.6l-2-1a3.2 3.2 0 00-2.3-.1 2.8 2.8 0 00-1.6 1.6z' />
      <path d='M163.3 142.6a6 6 0 011.7-1.6 5 5 0 012-.7 5.8 5.8 0 012.4.3 9.2 9.2 0 014.3 3 5.6 5.6 0 011 2.1 4.9 4.9 0 010 2.2 6 6 0 01-1 2.2 5.6 5.6 0 01-1.7 1.7 4.7 4.7 0 01-2.1.6 5.7 5.7 0 01-2.2-.3 8 8 0 01-2.1-1l-.8-.6 4.9-7-.4-.1a3.2 3.2 0 00-2.3-.7 3.1 3.1 0 00-2.2 1.4 3.5 3.5 0 00-.7 1.9 4.3 4.3 0 00.4 1.8l-2.1.4a5 5 0 01-.4-2.7 6.4 6.4 0 011.3-2.9zm8.8 6.3a3 3 0 00.5-1.1 2.6 2.6 0 000-1.2 3 3 0 00-.4-1 3.8 3.8 0 00-1-1h-.1l-3.3 4.6.2.1a3.4 3.4 0 002.2.7 2.5 2.5 0 002-1.1z' />
      <path d='M166.4 159.4l-1.9 1.8-1.4-1.5a3.4 3.4 0 01.2 2.2 3.9 3.9 0 01-1.1 2 4.3 4.3 0 01-3.8 1.2 9.5 9.5 0 01-6.6-6.8 4.3 4.3 0 011.5-3.8 3.7 3.7 0 012-1 3.4 3.4 0 012.1.4l.1-.1-4.7-4.9 1.9-1.8zm-11-3.8a2.8 2.8 0 00-1 2.1 3.1 3.1 0 001 2.2l1.5 1.6a3.2 3.2 0 002.2 1 2.8 2.8 0 002.1-.8 3.4 3.4 0 001-1.8 1.8 1.8 0 00-.4-1.7l-3-3a1.8 1.8 0 00-1.6-.5 3.4 3.4 0 00-1.8 1z' />
      <path d='M141.9 166.7a5 5 0 01-.1-1 2.9 2.9 0 01.1-1 3.3 3.3 0 01.5-1 4.4 4.4 0 011-1 3.7 3.7 0 013.2-.6 5.3 5.3 0 012.9 2.2l4.5 6.4-2.1 1.5-4.3-6.1c-1.1-1.6-2.3-1.9-3.6-1a3.7 3.7 0 00-.7.6 2.8 2.8 0 00-.5.8 2.2 2.2 0 000 1 1.8 1.8 0 00.3.8l4.7 6.7-2 1.5-7.2-10 2.1-1.5z' />
      <path d='M139.8 179.7l-2.3 1.2-5.6-11 2.3-1.1zm-.1 2.6a1.6 1.6 0 011.2-.2 1.3 1.3 0 01.8.7l.1.3a1.3 1.3 0 01.2 1 2 2 0 01-2.1 1 1.4 1.4 0 01-.8-.6l-.2-.4a1.3 1.3 0 010-1 1.5 1.5 0 01.8-.8z' />
      <path d='M123.8 173a6.3 6.3 0 013-.3 6.4 6.4 0 012.5 1l-1.1 2a5.3 5.3 0 00-1.9-.8 4 4 0 00-2 .2 2.7 2.7 0 00-1.4 1 1.4 1.4 0 00-.2 1.3 1.4 1.4 0 00.7.9 2.2 2.2 0 001.5 0l1.1-.3a6 6 0 013.2 0 3.1 3.1 0 011.8 2.2 3.6 3.6 0 01.2 1.6 3.3 3.3 0 01-.5 1.4 4.5 4.5 0 01-1.1 1.2 6.4 6.4 0 01-1.7.8 6.2 6.2 0 01-2.7.4 6.3 6.3 0 01-2.3-.8l1-2a4.2 4.2 0 001.5.6 3.6 3.6 0 001.8-.1 2.5 2.5 0 001.4-1 1.4 1.4 0 000-1.2 1.3 1.3 0 00-.7-.8 3.1 3.1 0 00-1.5 0l-1.1.2a5.4 5.4 0 01-3.2-.2 3 3 0 01-1.7-2 3.5 3.5 0 01.4-3.1 4.7 4.7 0 011.2-1.3 6.8 6.8 0 011.8-.9z' />
      <path d='M109.6 189.5l-2.6.2-.2-2a3.4 3.4 0 01-1.2 1.8 3.8 3.8 0 01-2.1.7 4.3 4.3 0 01-3.8-1.4 9.4 9.4 0 01-.7-9.4 4.3 4.3 0 013.5-2 3.7 3.7 0 012.2.4 3.4 3.4 0 011.4 1.6h.1l-.5-6.7 2.6-.2zm-6.2-10a2.8 2.8 0 00-2 1 3.2 3.2 0 00-.7 2.4l.2 2.1a3.2 3.2 0 001 2.2 2.8 2.8 0 002.2.7 3.3 3.3 0 002-.7 1.8 1.8 0 00.6-1.6l-.3-4.2a1.8 1.8 0 00-1-1.4 3.3 3.3 0 00-2-.4z' />
      <path d='M90.9 177.6a2.3 2.3 0 012.4 2.8l-1.1 14.9-2.6-.2 1.2-15.4H89l.1-2.2z' />
      <path d='M77.8 177.7h.1a4.7 4.7 0 01.7-.8 3.2 3.2 0 01.8-.6 3 3 0 011-.2 4.3 4.3 0 011.4 0 3.7 3.7 0 012.6 2 5.2 5.2 0 01.3 3.6l-1.8 7.6-2.5-.5 1.7-7.3c.4-1.9-.1-3-1.7-3.4a4 4 0 00-1 0 2.7 2.7 0 00-.9.1 2 2 0 00-.7.5 1.9 1.9 0 00-.4 1l-1.9 8-2.5-.6 2.8-12 2.5.6z' />
      <path d='M67.6 172a6.3 6.3 0 012.5 1.6 6.2 6.2 0 011.3 2.4l-2.1.8a5.4 5.4 0 00-1-1.8 4 4 0 00-1.6-1.1 2.6 2.6 0 00-1.7-.2 1.4 1.4 0 00-1 1 1.4 1.4 0 000 1 2.2 2.2 0 001 1l1 .6a5.8 5.8 0 012.4 2 3.1 3.1 0 010 2.8 3.7 3.7 0 01-.8 1.4 3.4 3.4 0 01-1.3.8 4.5 4.5 0 01-1.6.1 6.4 6.4 0 01-1.9-.4 5.5 5.5 0 01-3.5-3.5l2-.9a4.1 4.1 0 00.8 1.4 3.6 3.6 0 001.5 1 2.5 2.5 0 001.6.2 1.4 1.4 0 00.9-.8 1.3 1.3 0 000-1.2 3.2 3.2 0 00-1.2-1l-1-.6a5.4 5.4 0 01-2.3-2.2 3.1 3.1 0 010-2.5 4 4 0 011-1.4 3.7 3.7 0 011.3-.8 4.4 4.4 0 011.7-.2 6.6 6.6 0 012 .5z' />
      <path d='M46 162.3h.1a3.3 3.3 0 012-.5 3.7 3.7 0 012.1.8 4.3 4.3 0 011.8 3.6 7.7 7.7 0 01-2 4.5 7.7 7.7 0 01-3.9 3 4.3 4.3 0 01-3.8-1A3.8 3.8 0 0141 171a3.3 3.3 0 010-2.1h-.2l-4.4 5.6-2-1.6 10.8-13.7 2 1.5zm2.3 1.6a3.3 3.3 0 00-2-.8 1.8 1.8 0 00-1.5.7l-2.6 3.3a1.8 1.8 0 00-.4 1.7 3.4 3.4 0 001.3 1.7 2.8 2.8 0 002.2.7 3.2 3.2 0 002-1.3l1.3-1.7a3.2 3.2 0 00.8-2.3 2.8 2.8 0 00-1.1-2z' />
      <path d='M38.4 152.2a6 6 0 011.3 2 5 5 0 01.3 2.1 5.8 5.8 0 01-.7 2.3 8.1 8.1 0 01-1.6 2 8.5 8.5 0 01-2.2 1.6 6 6 0 01-2.3.6 4.8 4.8 0 01-2.1-.4 5.8 5.8 0 01-2-1.4 5.5 5.5 0 01-1.3-2 4.7 4.7 0 01-.2-2.1 6 6 0 01.7-2.1 8.4 8.4 0 011.5-2l.7-.6 5.8 6.1.3-.2a3.3 3.3 0 001-2.2 3.1 3.1 0 00-1-2.4 3.4 3.4 0 00-1.6-1 4.1 4.1 0 00-1.9 0v-2.1a4.8 4.8 0 012.7.1 6.5 6.5 0 012.6 1.7zm-7.8 7.5a3 3 0 001 .7 2.6 2.6 0 001.1.2 3.2 3.2 0 001.2-.3 4 4 0 001-.7l.2-.1-4-4.2-.1.2a3.4 3.4 0 00-1.2 2.1 2.5 2.5 0 00.8 2z' />
      <path d='M21.6 145.5a89.1 89.1 0 01-14-48.1 90.4 90.4 0 01.7-11.7l2.2.3a87.7 87.7 0 0013 58.4z' />
    </RoundContainer>

    <path d='M117.2 115.6q-16.2 0-16.2-24.7t16.2-24.7q16.1 0 16.1 24.7t-16.1 24.7zm0-3.7a9.8 9.8 0 009-4.7q2.8-4.8 2.8-13.1v-6.4q0-8.4-3-13.1a9.7 9.7 0 00-8.8-4.8q-6.1 0-9 4.8t-3 13v6.5q0 8.3 3 13t9 4.9z' />
    <path d='M93.4 114.8H62.7v-4.2l16.7-16a35.7 35.7 0 005.5-6.8 13.4 13.4 0 002.1-7.1v-1.3a9.8 9.8 0 00-2.5-7c-1.6-1.7-4-2.6-7.3-2.6s-5.7.8-7.4 2.4a14 14 0 00-3.7 6.2L62.4 77a19.3 19.3 0 011.9-4.1 12.8 12.8 0 013-3.5 14.2 14.2 0 014.2-2.4 17.3 17.3 0 015.8-.9 16.7 16.7 0 016 1 12.6 12.6 0 014.4 2.8 11.6 11.6 0 012.8 4.3 16 16 0 01.9 5.5 17.3 17.3 0 01-.7 5 19.8 19.8 0 01-2 4.3 26.8 26.8 0 01-3 4.1 56 56 0 01-3.8 4l-14.7 14h26.2z' />
    <path d='M111.4 136.2a5.4 5.4 0 01-2.6-.5 5.5 5.5 0 01-1.8-1.5l1.3-1.3a4.7 4.7 0 001.4 1.2 3.8 3.8 0 001.8.4 2.5 2.5 0 001.7-.5 1.8 1.8 0 00.6-1.4 1.5 1.5 0 00-.4-1.1 2.8 2.8 0 00-1.5-.6l-1-.2a5 5 0 01-2.7-1.2 3 3 0 01-.8-2.2 3.3 3.3 0 01.3-1.5 3 3 0 01.8-1 4 4 0 011.3-.7 5.8 5.8 0 011.7-.2 5.5 5.5 0 012.4.4 4.8 4.8 0 011.7 1.4l-1.4 1.2a3.6 3.6 0 00-1.1-1 3.5 3.5 0 00-1.7-.4 2.6 2.6 0 00-1.5.4 1.4 1.4 0 00-.6 1.2 1.4 1.4 0 00.4 1.2 3.4 3.4 0 001.5.5l1 .2a5 5 0 012.7 1.2 3 3 0 01.8 2.3 4 4 0 01-.3 1.5 3.2 3.2 0 01-.8 1.1 4 4 0 01-1.4.8 6 6 0 01-1.8.3z' />
    <path d='M86.6 136l-1.1-3.2H81l-1 3.2h-2l4-12h2.5l4.1 12zm-3.3-10.2l-1.8 5.3H85z' />
    <path d='M96.2 129.4l-1.3-2.5v9.1H93v-12h2.2l3.9 6.7 1.3 2.5v-9.1h1.9V136H100z' />
  </svg>
);

export default Stamp20Ans;
